
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@vueform/multiselect';
import router from '@/router';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import { SettingsProviderUsers } from '@/models/Interfaces';

@Options({
    props: {
        users: {
            type: [],
            required: true
        }
    },
    name: 'ProviderUsers',
    components: {
        Multiselect
    },
    data() {
        return {
            filteredUsers: []
        };
    },
    computed: {
        showUsers() {
            return this.users || [];
        }
    }
})
export default class ProviderUsers extends Vue {
    public showMore = false;
    public users: SettingsProviderUsers[] = [];
    public ShowDefaultUsers = true;
    public searchText = '';
    editUser(userId = '0') {
        router.push({ name: 'EditProviderUser', params: { id: userId } });
    }
    async getUsersByFilter() {
        this.$emit('getUsersByFilter', this.searchText);
    }
}
