
import { defineComponent, PropType, toRef } from 'vue';
import { ProviderSettingsData } from '@/models/Provider';
import Multiselect from '@vueform/multiselect';
import { useI18n } from 'vue-i18n';

export default defineComponent({
    components: { Multiselect },
    props: {
        provider: {
            type: Object as PropType<ProviderSettingsData>,
            required: true
        },
        editMode: {
            type: Boolean,
            default: false
        }
    },
    async setup(props) {
        const { t } = useI18n();
        const providerRef = toRef(props, 'provider');
        const vatTypeList = [
            { value: 0, name: t('provider.vatType.includeVat') },
            { value: 1, name: t('provider.vatType.noVat') }
        ];
        return { vatTypeList, providerRef };
    }
});
