
import { Options, Vue } from 'vue-class-component';
import { ProviderSettingsData } from '@/models/Provider';
import swal from 'sweetalert2';
import { computed, reactive } from 'vue';
import { generalStore } from '@/store';
import { Language } from '@/models/Product';
import Multiselect from '@vueform/multiselect';

@Options({
    name: 'ProviderAccount',
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        provider: {
            type: ProviderSettingsData
        }
    },
    data() {
        return {
            providerLogo: null
        };
    },
    components: {
        Multiselect
    }
})
export default class ProviderAccount extends Vue {
    async mounted() {
        if (this.langs.value !== null) {
            await generalStore.dispatch('loadAllLanguages');
        }
    }
    protected readonly provider!: ProviderSettingsData;

    public providerLogo: null | string = null;
    langs = computed<Language[]>(() => generalStore.getters.languages);
    langsSelect = reactive({
        valueProp: 'id',
        label: 'name',
        required: true,
        canDeselect: false,
        searchable: false,
        options: this.langs
    });
    clearImage() {
        this.providerLogo = null;
        this.provider.mainImageUrl = '';
        this.provider.mainImage = '';
    }

    getLangName(langId: string) {
        let result = '';
        if (langId) {
            const findIndex = this.langsSelect.options.findIndex(x => x.id == langId);
            if (findIndex >= 0) {
                result = this.langsSelect.options[findIndex].name;
            }
        }
        return result;
    }

    async uploadingImageValidation(e: any) {
        swal.showLoading();
        let file = e.target.files[0];
        const fileTypes: string[] = ['image/png'];
        if (file) {
            if (file.size > 5242880) {
                swal.fire({
                    icon: 'error',
                    text: 'File size should be less or equal to 5 MB'
                });
                file = null;
                return;
            }
            if (!fileTypes.find(t => t === file.type)) {
                swal.fire({
                    icon: 'error',
                    text: 'Allowing file type: png'
                });
                file = null;
                return;
            }
            if (typeof FileReader === 'function') {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.providerLogo = event.target.result;
                    this.provider.mainImage = event.target.result
                        .replace('data:image/jpeg;base64,', '')
                        .replace('data:image/png;base64,', '');
                };
                reader.readAsDataURL(file);
            }
        }
        swal.close();
    }

    setValidator(code: number, errorMessage: string) {
        if (code == 101) {
            const companyIdField = this.$refs.companyIdField as HTMLInputElement;
            companyIdField.setCustomValidity(errorMessage);
            const companyIdFieldError = this.$refs.companyIdFieldError as HTMLInputElement;
            companyIdFieldError.textContent = errorMessage;
        }
        if (code == 102) {
            const emailField = this.$refs.emailField as HTMLInputElement;
            emailField.setCustomValidity(errorMessage);
            const emailFieldError = this.$refs.emailFieldError as HTMLInputElement;
            emailFieldError.textContent = errorMessage;
        }
        if (code == 103) {
            const mobileField = this.$refs.mobileField as HTMLInputElement;
            mobileField.setCustomValidity(errorMessage);
            const mobileFieldError = this.$refs.mobileFieldError as HTMLInputElement;
            mobileFieldError.textContent = errorMessage;
        }
        if (code == 104) {
            const operationEmail = this.$refs.operationEmail as HTMLInputElement;
            operationEmail.setCustomValidity(errorMessage);
            const operationEmailError = this.$refs.operationEmailError as HTMLInputElement;
            operationEmailError.textContent = errorMessage;
        }
        if (code == 105) {
            const operationMobile = this.$refs.operationMobile as HTMLInputElement;
            operationMobile.setCustomValidity(errorMessage);
            const operationMobileError = this.$refs.operationMobileError as HTMLInputElement;
            operationMobileError.textContent = errorMessage;
        }
        if (code == 106) {
            const financeEmail = this.$refs.financeEmail as HTMLInputElement;
            financeEmail.setCustomValidity(errorMessage);
            const financeEmailError = this.$refs.financeEmailError as HTMLInputElement;
            financeEmailError.textContent = errorMessage;
        }
        if (code == 107) {
            const financeMobile = this.$refs.financeMobile as HTMLInputElement;
            financeMobile.setCustomValidity(errorMessage);
            const financeMobileError = this.$refs.financeMobileError as HTMLInputElement;
            financeMobileError.textContent = errorMessage;
        }
    }
    clearValidator() {
        // required
        // mobile validation
        const mobileField = this.$refs.mobileField as HTMLInputElement;
        mobileField.setCustomValidity('');
        const mobileFieldError = this.$refs.mobileFieldError as HTMLInputElement;
        mobileFieldError.textContent = this.$t('valid.isRequired');

        // required
        // email validation
        const emailField = this.$refs.emailField as HTMLInputElement;
        emailField.setCustomValidity('');
        const emailFieldError = this.$refs.emailFieldError as HTMLInputElement;
        emailFieldError.textContent = this.$t('valid.isRequired');

        // required
        // number validation
        const companyIdField = this.$refs.companyIdField as HTMLInputElement;
        companyIdField.setCustomValidity('');
        const companyIdFieldError = this.$refs.companyIdFieldError as HTMLInputElement;
        companyIdFieldError.textContent = this.$t('valid.isRequired');

        // not required
        // email validation
        const operationEmail = this.$refs.operationEmail as HTMLInputElement;
        operationEmail.setCustomValidity('');
        const operationEmailError = this.$refs.operationEmailError as HTMLInputElement;
        operationEmailError.textContent = '';

        // not required
        // mobile validation
        const operationMobile = this.$refs.operationMobile as HTMLInputElement;
        operationMobile.setCustomValidity('');
        const operationMobileError = this.$refs.operationMobileError as HTMLInputElement;
        operationMobileError.textContent = '';

        // not required
        // email validation
        const financeEmail = this.$refs.financeEmail as HTMLInputElement;
        financeEmail.setCustomValidity('');
        const financeEmailError = this.$refs.financeEmailError as HTMLInputElement;
        financeEmailError.textContent = '';

        // not required
        // mobile validation
        const financeMobile = this.$refs.financeMobile as HTMLInputElement;
        financeMobile.setCustomValidity('');
        const financeMobileError = this.$refs.financeMobileError as HTMLInputElement;
        financeMobileError.textContent = '';
    }
}
