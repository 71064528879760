
import { defineComponent, ref, computed, PropType, toRef } from 'vue';
import Multiselect from '@vueform/multiselect';
import Calendar from 'primevue/calendar';
import moment from 'moment';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Q from 'q';
import router from '@/router';
import { Currency, CurrencyRate } from '@/models/Interfaces';
import { generalStore } from '@/store';
export default defineComponent({
    props: {
        items: {
            type: Array as PropType<CurrencyRate[]>,
            required: true
        }
    },
    name: 'CurrencyRates',
    components: {
        Multiselect,
        Calendar
    },
    async setup(props, { emit }) {
        const localCurrencyId = generalStore.state.localCurrencyId;
        const currencies = computed<Currency[]>(() => generalStore.getters.currencies);
        const getCurrencyRatesToShow = toRef(props, 'items');
        const fromDate = ref<Date | null>(null);
        const toDate = ref<Date | null>(null);
        const currencyId = ref<string>('');
        const showSeeMore = ref(true);
        function ChangeRate(id = '') {
            if (id == '') {
                router.push({ name: 'NewProviderCurrencyRate' });
            } else {
                router.push({ name: 'ProviderCurrencyRate', params: { id } });
            }
        }
        async function getCurrRateByFilter() {
            swal.showLoading();
            const fromDate2 = fromDate.value ? moment(fromDate.value).format('DD/MM/YYYY') : '';
            const toDate2 = toDate.value ? moment(toDate.value).format('DD/MM/YYYY') : '';
            const currencyId2 = currencyId.value;
            const apiPromice = api.filterCurrencyRate(currencyId2, fromDate2, toDate2);
            await Q.delay(400);
            const apiResult = await apiPromice;
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
                return;
            }
            if (apiResult.data) {
                emit('setCurrencies', apiResult.data.items);
                showSeeMore.value = false;
                swal.close();
            }
        }
        function getCurrDate(date: Date) {
            return moment(date).format('DD/MM/YYYY');
        }

        return {
            ChangeRate,
            getCurrRateByFilter,
            getCurrDate,
            currencies,
            showSeeMore,
            getCurrencyRatesToShow,
            fromDate,
            toDate,
            currencyId,
            localCurrencyId
        };
    }
});
