
import { defineComponent, ref, nextTick } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import swal from 'sweetalert2';
import q from 'q';
import { useI18n } from 'vue-i18n';
import { ProviderDocumentType } from '@/models/Interfaces';
import { api } from '@/services/Api';

export default defineComponent({
    components: {
        OverlayModal
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    name: 'ProviderDocumentTypeModal',
    async setup(props, { emit }: any) {
        const maxWidth = window.innerWidth;
        const isOpen = ref(false);
        const { t } = useI18n();
        const docType = ref<ProviderDocumentType>({
            createDate: '',
            id: '',
            name: ''
        });
        const isNew = ref(false);

        async function open(req: ProviderDocumentType | null = null) {
            swal.showLoading();
            if (req == null) {
                isNew.value = true;
                docType.value = {
                    name: '',
                    createDate: '',
                    id: ''
                };
            } else {
                isNew.value = false;
                docType.value = {
                    name: req.name,
                    createDate: req.createDate,
                    id: req.id
                };
            }

            await nextTick();
            await q.delay(400);
            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'info',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                return;
            } else {
                swal.showLoading();
                const apiPromise = api.createDocType(docType.value);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (apiResult.data) {
                    await swal.fire({
                        position: 'center',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    if (isNew.value) {
                        emit('createDocType', apiResult.data);
                    } else {
                        emit('updateDocType', apiResult.data);
                    }
                    close();
                }
            }
        };

        return {
            maxWidth,
            isOpen,
            open,
            close,
            docType,
            isNew,
            submitForm
        };
    }
});
