
import { Options, Vue } from 'vue-class-component';
import moment from 'moment';
import router from '@/router';
import Q from 'q';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { ProviderProfile } from '@/models/Interfaces';

@Options({
    props: {
        items: {
            type: [],
            required: true
        }
    },
    name: 'ProviderProfiles',
    components: {},
    data() {
        return {
            wasSearch: false,
            itemsAfterSearch: [],
            searchText: ''
        };
    },
    computed: {
        itemsToShow() {
            if (this.wasSearch) {
                return this.itemsAfterSearch;
            }
            return this.items;
        }
    }
})
export default class ProviderProfiles extends Vue {
    protected readonly providerId!: number;
    public showMore = false;
    public wasSearch = false;
    public searchText = '';
    public itemsAfterSearch: ProviderProfile[] = [];

    getDate(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }
    EditProfile(profileId: number) {
        router.push({ name: 'EditProviderProfile', params: { id: profileId } });
    }
    async searchProfile() {
        swal.showLoading();
        this.wasSearch = true;
        const apiResult = await api.getProviderProfilesData(this.searchText);
        await Q.delay(400);
        if (apiResult.errorMessage) {
            swal.fire({
                icon: 'error',
                text: apiResult.errorMessage
            });
            return;
        }
        if (apiResult.data) {
            this.itemsAfterSearch = apiResult.data || [];
        }
        swal.close();
    }
}
