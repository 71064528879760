
import { defineComponent, ref, onMounted } from 'vue';
import ProviderAccount from '@/components/ProviderSettings/ProviderAccount.vue';
import ProviderCompanyInfo from '@/components/ProviderSettings/ProviderCompanyInfo.vue';
import ProviderSubscription from '@/components/ProviderSettings/ProviderSubscription.vue';
import ProviderCashierComponent from '@/components/ProviderSettings/ProviderCashierComponent.vue';
import ProviderUsers from '@/components/ProviderSettings/ProviderUsers.vue';
import ProviderProfiles from '@/components/ProviderSettings/ProviderProfiles.vue';
import ProviderDocuments from '@/components/ProviderSettings/ProviderDocuments.vue';
import ProviderCurrencyRates from '@/components/ProviderSettings/ProviderCurrencyRates.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { ProviderSettingsData, ProviderAccountEntity } from '@/models/Provider';
import Q from 'q';
import mixins from '@/mixin';
import InputNumber from 'primevue/inputnumber';
import { generalStore } from '@/store';
import { IdName, Currency, CurrencyRate, ProviderDocumentType } from '@/models/Interfaces';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import ProviderDocumentTypeModal from '@/components/ProviderSettings/ProviderDocumentTypeModal.vue';

export default defineComponent({
    name: 'ProviderSettings',
    components: {
        ProviderAccount,
        ProviderCompanyInfo,
        ProviderUsers,
        ProviderSubscription,
        ProviderProfiles,
        InputNumber,
        ProviderCashierComponent,
        ProviderCurrencyRates,
        ProviderDocuments,
        ProviderDocumentTypeModal
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        }
    },
    async setup() {
        const provider = ref(new ProviderSettingsData());
        const originProvider = ref(new ProviderSettingsData());
        const banks = ref<IdName[]>([]);
        const currencies = ref<Currency[]>([]);
        const { t } = useI18n();
        const providerAccountRef = ref<ProviderAccount>();
        const currencyRatesItems = ref<CurrencyRate[]>([]);
        const modalProviderDocumentTypeModal = ref<InstanceType<typeof ProviderDocumentTypeModal>>();

        function back() {
            router.go(-1);
        }

        function setCurrencies(items: CurrencyRate[]) {
            currencyRatesItems.value = items;
        }
        async function getUsersByFilter(searchText: string) {
            swal.showLoading();
            const apiResult = await api.getUsersByFilter(searchText);
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
                return;
            }
            provider.value.users = apiResult.data?.users || [];

            swal.close();
        }

        async function onSave() {
            if (!mixins.methods.isEquivalent(provider.value, originProvider.value)) {
                swal.showLoading();
                const apiResponse = await api.saveProviderDetails(provider.value);
                if (apiResponse.errorMessage) {
                    if (apiResponse.errorCode) {
                        providerAccountRef.value?.setValidator(apiResponse.errorCode, apiResponse.errorMessage);
                    }
                    swal.fire({
                        icon: 'error',
                        text: apiResponse.errorMessage
                    });
                    return false;
                }
            }
            await swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Provider updated',
                showConfirmButton: false,
                timer: 1500
            });
            router.go(-1);
            return true;
        }
        async function submitForm(event: any) {
            providerAccountRef.value?.clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
            } else {
                onSave();
            }
        }
        function addAccount() {
            provider.value.providerAccounts.unshift(new ProviderAccountEntity());
        }
        async function removeProviderAccount(indexToDelete: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('editProviderPage.removeProviderAccount'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('button.yes'),
                cancelButtonText: t('button.no')
            });
            if (swalResult.isConfirmed) {
                provider.value.providerAccounts.splice(indexToDelete, 1);
            }
        }
        function getBankName(bankId: number | null) {
            if (!bankId) {
                return '';
            }

            return banks.value.find(x => x.id == bankId.toString())?.name ?? '';
        }

        function getCurrenciesToShow(ids: string[]) {
            return currencies.value
                .filter(x => ids.includes(x.id))
                .map(x => x.symbol)
                .join(', ');
        }

        function notValidAccountNumber(accountNumber: string) {
            const reg = new RegExp('^[0-9]*$');
            if (reg.test(accountNumber) == false || accountNumber.length != 16) {
                return true;
            }
            return false;
        }
        function checkIsNumber(index: number, evt: Event) {
            let res = 0;
            const target = evt.target as HTMLTextAreaElement;
            res = parseInt(target.value);
            const account: ProviderAccountEntity = provider.value.providerAccounts[index];
            if (isNaN(res)) {
                account.accountNumber = '';
            } else {
                account.accountNumber = res.toString();
            }
        }
        function addOption() {
            provider.value.orderSourceOptions.push({
                id: '',
                text: ''
            });
        }
        async function deleteSourceOption(index: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('editProviderPage.removeProviderOrderSourceAlert'),
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('button.yes'),
                cancelButtonText: t('button.no')
            });
            if (swalResult.isConfirmed) {
                provider.value.orderSourceOptions.splice(index, 1);
            }
        }

        function changeReceiptProviderSetting(newValue: boolean) {
            provider.value.autoPrintReceipt = newValue;
        }

        function deleteDocType(id: string) {
            const deleteIndex = provider.value.providerDocumentTypes.findIndex(x => x.id == id);
            if (deleteIndex >= 0) {
                provider.value.providerDocumentTypes.splice(deleteIndex, 1);
            }
        }

        function editDocType(id: string) {
            const docType = provider.value.providerDocumentTypes.find(x => x.id == id);
            modalProviderDocumentTypeModal.value?.open(docType);
        }
        function createDocType(docType: ProviderDocumentType) {
            provider.value.providerDocumentTypes.push(docType);
        }
        function updateDocType(docType: ProviderDocumentType) {
            const existDocType = provider.value.providerDocumentTypes.find(x => x.id == docType.id);
            if (existDocType) {
                existDocType.name = docType.name;
            }
        }

        const onMountedCall = async () => {
            swal.showLoading();
            banks.value = await generalStore.dispatch('getBanks');
            currencies.value = generalStore.getters.currencies;
            const apiResult = await api.GetProviderSettings();
            await Q.delay(400);
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                provider.value = apiResult.data.provider;
                currencyRatesItems.value = JSON.parse(JSON.stringify(apiResult.data.provider.providerCurrencyRates));
                originProvider.value = JSON.parse(JSON.stringify(apiResult.data.provider));
            }
            swal.close();
        };
        onMounted(onMountedCall);

        return {
            provider,
            back,
            getUsersByFilter,
            submitForm,
            addAccount,
            removeProviderAccount,
            getBankName,
            getCurrenciesToShow,
            notValidAccountNumber,
            checkIsNumber,
            addOption,
            deleteSourceOption,
            providerAccountRef,
            currencyRatesItems,
            setCurrencies,
            changeReceiptProviderSetting,
            deleteDocType,
            editDocType,
            modalProviderDocumentTypeModal,
            createDocType,
            updateDocType
        };
    }
});
