
import { Options, Vue } from 'vue-class-component';
import { ProviderSettingsData } from '@/models/Provider';
import Multiselect from '@vueform/multiselect';
import { mapState } from 'vuex';
import { SubsctiptionType } from '@/models/Interfaces';

@Options({
    name: 'ProviderSubscription',
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        provider: {
            type: ProviderSettingsData
        }
    },
    data() {
        return {
            vatTypeList: [
                { value: 0, name: this.$t('provider.vatType.includeVat') },
                { value: 1, name: this.$t('provider.vatType.noVat') }
            ]
        };
    },
    components: {
        Multiselect
    },
    computed: {
        ...mapState({
            subscriptions: 'subscriptions'
        }),
        selectedSubscriptions() {
            return this.provider.subscriptionId
                ? this.subscriptions.find((x: SubsctiptionType) => x.id == this.provider.subscriptionId)
                : {};
        }
    }
})
export default class ProviderSubscription extends Vue {
    protected readonly provider!: ProviderSettingsData;
    protected readonly subscriptions!: SubsctiptionType[];
}
