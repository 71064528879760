
import { defineComponent, PropType } from 'vue';
import { ProviderDocumentType } from '@/models/Interfaces';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import q from 'q';
import { api } from '@/services/Api';

export default defineComponent({
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        docTypes: {
            type: Array as PropType<ProviderDocumentType[]>,
            required: true
        }
    },
    async setup(props, { emit }) {
        const { t } = useI18n();
        async function deleteDocType(id: string) {
            const swalAction = await swal.fire({
                icon: 'info',
                text: t('provider.alert.delete-doctype'),
                customClass: {
                    confirmButton: 'green-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalAction.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const apiPromise = api.deleteDocType(id);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                await swal.fire({
                    position: 'center',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500
                });
                emit('deleteDocType', id);
            }
            swal.close();
        }
        function editDocType(id = '') {
            emit('editDocType', id);
        }
        return { deleteDocType, editDocType };
    }
});
